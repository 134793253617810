<template>
  <div class="table-responsive" v-show="show">
    <table class="table align-middle">
      <tbody>
        <tr v-for="row in rows" :key="row.id">
          <td width="40">
            <ejs-checkbox
              :checked="isDetailRowChecked(row)"
              @change="(args) => onDetailRowCheckChange(args, row)"
            />
          </td>
          <td width="40">
            <OrdersCount
              variant="bullet"
              :color="statusColor(row)"
              :total="row.incident !== null ? 1 : 0"
              :open="
                row.incident !== null && row.incident.close === false ? 1 : 0
              "
            />
          </td>
          <td v-if="viewCol('product')">
            <font-awesome-icon icon="truck" class="mr-2" v-if="row.ddmp.delivery_type==3" />
            <strong>{{ productName(row) }}</strong>
            <br />
            <span>{{ variantName(row) }}</span>
          </td>
          <td v-if="viewCol('family')">{{ familyName(row) }}</td>
          <td width="250" v-if="viewCol('productType')">
            {{ productType(row) }}
          </td>
          <td width="70" v-if="viewCol('orderId')">#{{ orderId(row) }}</td>
          <td width="120">
            <OrdersWeightButton :row="row" :parentRowIndex="parentRowIndex" />
          </td>
          <td width="100" class="text-right">
            <strong>{{ price(row) }}</strong>
          </td>
          <td width="40">
            <dropdown-menu
              :items="actions(row)"
              :select="(e) => onActionSelect(e, row)"
            >
              <font-awesome-icon icon="ellipsis-h"
            /></dropdown-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import { formatPrice } from "../../../../helpers/common";
import { ORDER_ACTION } from "../../../../config/global";
import EventBus from "../../../../helpers/EventBus";
import { PERMS } from "../../../../helpers/acl";
import OrdersWeightButton from "./OrdersWeightButton";
import OrdersCount from "./OrdersCount";

export default {
  name: "OrdersTableDetailRows",

  components: {
    OrdersWeightButton,
    OrdersCount,
  },

  props: {
    statusMap: { type: Array, required: true },
    grid: { type: Object, required: true },
    parentData: { type: Object, required: true },
    parentRowIndex: { type: Number },

    rows: { type: Array, default: () => [] },
    show: { type: Boolean, default: true },
    cols: {
      type: Array,
      default: () => ["product", "family", "productType", "orderId"],
    },
    actLabel: { type: String, default: "ritirato" },
    skipDirectDelivery: { type: Boolean, default: false },
  },

  data() {
    return {
      selected: [],
    };
  },

  mounted() {
    EventBus.$on("orders:rowSelected", this.onParentSelected);
    EventBus.$on("orders:rowSelectedAll", this.selectAll);

    EventBus.$on("orders:rowDeselected", this.onParentDeselected);
    EventBus.$on("orders:rowDeselectedAll", this.deselectAll);
  },

  beforeDestroy() {
    EventBus.$off("orders:rowSelected", this.onParentSelected);
    EventBus.$off("orders:rowSelectedAll", this.selectAll);

    EventBus.$off("orders:rowDeselected", this.onParentDeselected);
    EventBus.$off("orders:rowDeselectedAll", this.deselectAll);
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  watch: {
    selected(val) {
      EventBus.$emit("orders:detail", {
        parentId: this.parentData.id,
        selected: [...val],
      });
    },
  },

  methods: {
    actions(rowData) {
      const canEdit = this.canView(PERMS.ORDERS_EDIT);
      const haveIncident = get(rowData, "incident", null) !== null;
      const haveIncidentOpen = get(rowData, "incident.close", null) === false;
      let canEditDirect = canEdit
      if (!this.skipDirectDelivery){
        canEditDirect = canEdit && !rowData.ddmp.delivery_type==3;
      }
      const actions = [
        {
          id: ORDER_ACTION.DONE,
          text: "Segna come "+this.actLabel,
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: "Segna come NON "+this.actLabel,
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: "Apri segnalazione",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT_EDIT,
          text: "Modifca segnalazione",
          disabled: false,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: "Annulla",
          disabled: !canEdit,
        },
      ];
      return actions.filter((action) => {
        if (action.id === ORDER_ACTION.INCIDENT && haveIncident) {
          return false;
        }
        if (action.id === ORDER_ACTION.INCIDENT_EDIT && !haveIncidentOpen) {
          return false;
        }
        return true;
      });
    },

    isDetailRowChecked(rowData) {
      return this.selected.findIndex((item) => item.id === rowData.id) > -1;
    },

    onDetailRowCheckChange(args, rowData) {
      const { checked } = args;
      const parentSelectedIds = this.grid
        .getSelectedRecords()
        .map((item) => item.id);

      // deselect parent logic
      if (checked) {
        if (!this.isDetailRowChecked(rowData)) {
          this.selected.push(rowData);
          if (this.selected.length === this.rows.length) {
            this.grid.selectRow(this.parentRowIndex);
          }
        }
      } else {
        // deselect parent logic
        if (this.isDetailRowChecked(rowData)) {
          this.selected = this.selected.filter(
            (item) => item.id !== rowData.id
          );
          if (parentSelectedIds.includes(this.parentData.id)) {
            this.grid.selectRow(this.parentRowIndex, true);
          }
        }
      }
    },

    onParentSelected(args) {
      if (args.data.id === this.parentData.id) {
        this.selectAll();
      }
    },

    onParentDeselected(args) {
      if (args.data.id === this.parentData.id) {
        this.deselectAll();
      }
    },

    selectAll() {
      console.log("selectAll");
      console.log(this.rows);
      this.selected = [...this.rows];
    },

    deselectAll() {
      this.selected = [];
    },

    // status
    statusColor(rowData) {
      const item = this.statusMap.find((item) =>
        item.status.includes(rowData.status)
      );
      return item ? item.color : "";
    },

    // familyName
    familyName(rowData) {
      return get(rowData, "order.family.name");
    },

    productName(rowData) {
      return get(
        rowData,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );
    },

    variantName(rowData) {
      return get(rowData, `ppm.lot.translations.${this.lang}.name`);
    },

    // product type
    productType(rowData) {
      return get(rowData, `producttype`);
    },

    // orderId
    orderId(rowData) {
      return get(rowData, "order.id");
    },

    price(rowData) {
      return formatPrice(rowData.price, this.currencySymbol).format();
    },

    onActionSelect(args, row) {
      this.$emit("onActionSelect", { args, row });
    },

    viewCol(col) {
      return this.cols.includes(col);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
